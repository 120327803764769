<!--
* 创建人：邓国伦
* 日 期：
* 描 述：学生综测素质测评减分登记
-->
<!--view 模板-->
<template>
  <div class="container">
    <!--=====================================顶部标题======================================-->
    <div class="page-title">学生综测素质测评减分登记</div>
    <!--=====================================工具栏========================================-->
    <div class="page-tool">
      <div>
        <sy-xueqi
          v-model="xqid"
          :selectdqxq="true"
          style="display: inline"
        ></sy-xueqi>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="small"
          @click="getDataPageList"
          >搜索</el-button
        >
      </div>

      <el-button-group>
        <el-button
          size="small"
          icon="el-icon-refresh"
          @click="reload()"
        ></el-button>
        <el-button
          size="small"
          v-if="hasPerms('sy_add')"
          icon="el-icon-plus"
          @click="showAdd"
          >新增</el-button
        >
        <el-button
          size="small"
          v-if="hasPerms('sy_delete')"
          icon="el-icon-delete"
          @click="delmodre"
          >删除</el-button
        >
        <el-button
          size="small"
          v-if="hasPerms('sy_guidang')"
          icon="el-icon-box"
          @click="guidang(null)"
          >归档</el-button
        >
      </el-button-group>
    </div>
    <!--=============================表格========================================-->
    <div class="c_table">
      <el-table
        v-loading="tableloading"
        :data="dataPageList"
        :height="windowHeight - 270"
        border
        style="width: 100%"
        header-cell-class-name="tableStyle"
        highlight-current-row
        @selection-change="selectRowChange"
        :row-style="{ height: '20px' }"
        :cell-style="{ padding: '5px' }"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="xueqi" label="学期" width="200">
        </el-table-column>
        <el-table-column prop="sj" label="时间" width="120"> </el-table-column>
        <el-table-column prop="lsxm" label="辅导员" width="100">
        </el-table-column>
        <el-table-column prop="xsxm" label="学生" width="80"></el-table-column>
        <el-table-column
          prop="njfz"
          label="拟减分值"
          width="80"
        ></el-table-column>
        <el-table-column prop="jfsy" label="减分事由" width="200">
        </el-table-column>
        <el-table-column prop="jfyj" label="减分依据" width="200">
        </el-table-column>
        <el-table-column prop="fjbh" label="是否归档">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.sfgd === 1" type="danger" size="mini"
              >是</el-tag
            >
            <el-tag v-else type="success" size="mini">否</el-tag>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="120">
          <template slot-scope="scope">
            <el-button
              size="mini"
              v-if="hasPerms('sy_edit')"
              @click="showEdit(scope.row.id)"
              type="text"
              >{{ scope.row.sfgd !== 1 ? '编辑' : '查看' }}</el-button
            >
            <el-popconfirm
              class="pl5"
              v-if="hasPerms('sy_delete') && scope.row.sfgd !== 1"
              title="确定删除吗？"
              @confirm="del(scope.row.id)"
            >
              <el-button slot="reference" type="text" size="mini">
                删除
              </el-button>
            </el-popconfirm>
            <el-button
              size="mini"
              v-if="hasPerms('sy_guidang') && scope.row.sfgd !== 1"
              @click="guidang(scope.row.id)"
              type="text"
              >归档</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!--=======================================分页================================================-->
      <div class="pagebox">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.rows"
          layout="total, prev, pager, next, jumper"
          :total="pagination.records"
        >
        </el-pagination>
      </div>
    </div>
    <!------------------添加 修改 -------------->
    <el-dialog
      :title="operation == 1 ? '新增' : '编辑'"
      :visible.sync="dialogFormVisible"
      width="40%"
      :close-on-click-modal="false"
      @close="closeDialog"
    >
      <el-form
        :model="info"
        v-if="dialogFormVisible"
        ref="formRef"
        :rules="infoRules"
      >
        <el-form-item label="学期" label-width="120px" prop="XQID">
          <sy-xueqi
            v-model="info.XQID"
            :selectdqxq="true"
            style="display: inline"
          ></sy-xueqi>
        </el-form-item>
        <el-form-item label="减分学生" label-width="120px" prop="XH">
          <el-select
            v-model="info.XH"
            filterable
            remote
            reserve-keyword
            placeholder="选择学生"
            :remote-method="remoteMethod"
            :loading="fjloading"
          >
            <el-option
              v-for="item in fjbhoptions"
              :key="item.id"
              :label="item.xm"
              :value="item.xh"
            >
              <span style="float: left">{{ item.xm }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{
                item.xh
              }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="时间" label-width="120px" prop="SJ">
          <el-date-picker
            v-model="info.SJ"
            type="date"
            align="right"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="拟减分值" label-width="120px" prop="NJFZ">
          <el-input-number
            v-model="info.NJFZ"
            controls-position="right"
            :min="0"
            :max="100"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="减分事由" label-width="120px" prop="JFSY">
          <el-input
            v-model="info.JFSY"
            autocomplete="off"
            type="textarea"
            :rows="3"
            placeholder="减分事由"
          ></el-input>
        </el-form-item>
        <el-form-item label="减分依据" label-width="120px" prop="JFYJ">
          <el-input
            v-model="info.JFYJ"
            autocomplete="off"
            type="textarea"
            :rows="3"
            placeholder="减分依据"
          ></el-input>
        </el-form-item>
        <el-form-item label="" label-width="120px" v-if="info.SFGD === 1">
          <el-tag type="danger">已归档</el-tag>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" v-if="info.SFGD !== 1">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveForm(info.ID)">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<!-- javascript脚本-->
<script>
import fdy from '../../api/fdy'
import { getXSPageList } from '../../api/jcxx'
import { getAuthorizeButtonColumnList } from '../../api/system'
export default {
  data() {
    return {
      active: 0,
      pagination: {
        rows: 10,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      windowHeight: document.documentElement.clientHeight, // 实时屏幕高度
      xqid: null,
      keyword: '',
      keyValue: '',
      ids: null,
      tableloading: true,
      fjloading: false,
      buttonauth: {},
      dataPageList: [],
      formdata: {},
      operation: 1, // 当前操作1=添加，0=编辑
      dialogFormVisible: false, // 是否显示弹出框
      info: {
        ID: '',
        XQID: '',
        JFSY: '',
        SJ: null,
        NJFZ: 0,
        JFYJ: '',
        XH: '',
      },
      infoRules: {
        XQID: [{ required: true, message: '请选择学期', trigger: 'change' }],
        SJ: [
          {
            required: true,
            message: '请选择时间',
            trigger: 'blur',
          },
        ],
        JFSY: [{ required: true, message: '请填写减分事由', trigger: 'blur' }],
        XH: [{ required: true, message: '请选择学生', trigger: 'blur' }],
        JFYJ: [{ required: true, message: '请填写减分依据', trigger: 'blur' }],
        NJFZ: [{ required: true, message: '请填写拟减分值', trigger: 'blur' }],
      },
      fjbhoptions: null,
    }
  },
  watch: {},
  components: {
    'sy-xueqi': () => import('../../components/dropdown/dropdown_xueqi'),
  },
  created() {
    getAuthorizeButtonColumnList({ url: this.$route.path }).then((res) => {
      if (res.code === 200) {
        this.buttonauth = res.data.btns
      } else {
        this.buttonauth = {}
      }
    })
    this.getDataPageList()
  },
  methods: {
    // 判断是否有该按钮的权限
    hasPerms(code) {
      if (Object.prototype.hasOwnProperty.call(this.buttonauth, code)) {
        return true
      } else {
        return false
      }
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.pagination.page = val
      this.getDataPageList()
    },
    reload() {
      this.$router.go(0)
    },
    // 当前行发生变化
    selectRowChange(rows) {
      this.ids = null
      this.ids = rows.map((v) => {
        return v.id
      })
    },
    // 选择房间
    remoteMethod(query) {
      if (query !== '') {
        this.fjloading = true
        var dat = {
          queryJson: JSON.stringify({ keyword: query }),
          pagination: JSON.stringify({
            rows: 10,
            page: 1,
            sord: 'DESC',
            records: 0,
            total: 0,
          }),
        }
        getXSPageList(dat).then((res) => {
          this.fjloading = false
          this.fjbhoptions = res.data.rows
        })
      } else {
        this.fjbhoptions = []
      }
    },
    // 获取分页信息
    getDataPageList() {
      this.tableloading = true
      fdy
        .get_jfdj_pagelist({
          queryJson: JSON.stringify({ xqid: this.xqid }),
          pagination: JSON.stringify(this.pagination),
        })
        .then((res) => {
          if (res.code === 200) {
            res.data.rows.forEach((m) => {
              m.sj = this.moment(m.sj).format('YYYY-MM-DD')
            })
            this.tableloading = false
            this.pagination.page = res.data.page
            this.pagination.total = res.data.total
            this.pagination.records = res.data.records
            this.dataPageList = res.data.rows
          } else {
            this.tableloading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 显示添加弹出框
    showAdd() {
      this.operation = 1
      this.keyValue = ''
      this.dialogFormVisible = true
    },
    // 显示编辑弹出框
    showEdit(id) {
      if (id === '') {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
        this.operation = 0
        fdy.get_jfdj_formdata({ id: id }).then((res) => {
          if (res.code === 200) {
            Object.keys(res.data).forEach((k) => {
              this.info[k.toUpperCase()] = res.data[k]
            })
            this.info.ID = res.data.id
            this.info.SJ = res.data.sj
            this.info.JFYJ = res.data.jfyj
            this.info.XH = res.data.xh
            this.info.JFSY = res.data.jfsy
            this.info.JFYJ = res.data.jfyj
            this.info.NJFZ = res.data.njfz == null ? 0 : res.data.njfz
            this.remoteMethod(res.data.xh)
            this.dialogFormVisible = true
          } else {
            this.$message.error(res.message)
          }
        })
      }
    },
    // 保存信息
    saveForm(id) {
      if (this.operation === 1) {
        this.keyValue = ''
        this.info.ID = ''
      } else {
        this.info.ID = id
      }
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) {
          return false
        } else {
          fdy.save_jfdj(this.info).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.message)
              this.dialogFormVisible = false
              this.getDataPageList()
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    del(id) {
      if (!id) {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
        fdy.del_jfdj({ id: id }).then((res) => {
          if (res.code === 200) {
            this.$message.success(res.message)
            this.getDataPageList()
          } else {
            this.$message.error(res.message)
          }
        })
      }
    },
    // 批量删除
    delmodre() {
      if (!this.ids || this.ids.length < 1) {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
        this.$confirm('是否确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            fdy.del_jfdj({ ids: this.ids }).then((res) => {
              if (res.code === 200) {
                this.$message.success(res.message)
                this.getDataPageList()
              } else {
                this.$message.error(res.message)
              }
            })
          })
          .catch(() => {})
      }
    },
    guidang(id) {
      var dat = null
      if (id) {
        dat = { id: id }
      } else {
        if (!this.ids || this.ids.length < 1) {
          this.$message.error('您没有选中任何数据项，请选中后在操作！')
          return 0
        }
        dat = { ids: this.ids }
      }
      fdy.guidang_jfdj(dat).then((res) => {
        if (res.code === 200) {
          this.$message.success(res.message)
          this.getDataPageList()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 当添加/编辑弹出框关闭时调用
    closeDialog() {
      this.active = 0
      this.$nextTick(() => {
        this.info = JSON.parse(JSON.stringify(this.info))
      })
      this.$refs.formRef.resetFields()
    },
  },
}
</script>
<!-- CSS样式 -->
<style scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.page-title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}
.page-tool {
  padding: 20px;
  width: 100%;
  height: 70px;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.page-tool .el-input {
  width: 300px;
}

.page-tool .el-button {
  margin-left: 10px;
}
.c_table {
  height: 100%;
  overflow-y: auto;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #333;
  font-weight: 400;
  padding: 5px 0;
}
</style>
